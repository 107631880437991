/*

Logo animation at carousel?

*/

import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import KoandcoVideo from "../../assets/images/koandco/koandcoanimation.mp4"

import "./template-styles.scss"
import "./ko-and-co.scss"
import Img from "../../assets/images/koandco-images"
import DesktopVideo from "../../assets/images/koandco/ko-6-video.mp4"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

const black = "#212931"
const white = "#ffffff"
const beige = "#E0DED0"
const pink = "#D3455F"

const KoandcoProject = () => {
  ProjectAnimations()
  return (
    <div className="project koandco">
      <SEO
        title="KO & CO"
        description="A brand strategy and eye-popping platform for a garden and landscape business led by the most colourful identity in the industry. Brand strategy, design platform, name, tagline, illustration, animation, website, tone-of-voice, key messaging and copy content, brand and marketing collateral"
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div>
            <h1>KO & CO</h1>
            <p>
              No shrinking violets here. The brand for KO & CO, which is led by
              the charismatic Kim Odering, could be nothing but full noise, full
              colour and full of life. And vastly different from the
              competition. KO & CO bring outdoor spaces to life with handcrafted
              beauty. We did the same for the brand.
              <br />
              <br />
              Strategy, brand platform, name, logo, illustration, website, tone
              of voice and copy, brand and marketing material.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko2" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: black }}
        >
          <div>
            <p>
              For authenticity, and inspired by the charm of botanical
              illustration, the look and feel was developed by hand, starting as
              Kim does, with a drawing. From this we developed four ‘wallpaper’
              designs and colourways – one for each season – for a unique and
              versatile design base.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko3" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-3">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko4" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div>
            <p>
              Landscapers think three-dimensionally. So we did too, cutting and
              handcrafting beautiful 3D forms using the wallpaper design for the
              elements.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-4">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko11" />
        </div>
      </Slide>
      <Slide className="section-5">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko12" />
        </div>
      </Slide>

      <div className="section-6 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div>
            <p>
              We handcrafted four different logos in the seasonal colourways.
              The intricate 3D paper sculptures were photographed and rendered
              for digital use.{" "}
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko10" />
        </div>
      </Slide>
      <Slide className="section-8">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko5" />
        </div>
      </Slide>
      <Slide className="section-9">
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: black }}
        >
          <div>
            <p>
              Bright, bold, and shaped by the idea of garden rooms, this dynamic
              logo comes alive with animation. The bespoke website delivers a
              rewarding interactive experience, surprising the user as the logo
              and 3D paper garden grow onto the screen or sway with the breeze
              created by the cursor. Just as gardens change through the seasons,
              every refresh changes the flora and colourway.
              <br />
              <br />
              Experience the full site{" "}
              <a
                href="https://koandco.nz/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline"
              >
                here
              </a>
            </p>
          </div>
        </div>

        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <video
            src={KoandcoVideo}
            autoPlay
            playsInline
            muted
            loop
            className="h-full object-cover"
          ></video>
        </div>
      </Slide>

      <Slide className="section-10">
        <div
          className="desktop panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: beige }}
        >
          <div className="screen-container__inner-container">
            <Img src="ko6" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={DesktopVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>
      <Slide className="section-11">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko7" />
        </div>
      </Slide>
      <Slide className="section-12">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div>
            <p>
              The brand assets stand out. KO & CO's business cards are seasonal
              packets of seeds, their site signage pops, and their notebooks are
              like fresh flowers on the table. The tone of voice was developed
              as warm and informative, every bit Kim's natural way of speaking.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-13">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko8" />
        </div>
      </Slide>
      <Slide className="section-14">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ko9" />
        </div>
      </Slide>
      <Slide className="section-15">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko13" />
        </div>{" "}
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ko14" />
        </div>
      </Slide>
      <Slide className="section-16">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: pink, color: white }}
        >
          <div>
            <p>
              Original, engaging, disruptive and instantly recognisable, KO &
              CO's brand is a compelling vehicle of persuasion for a company
              that's thriving and in demand with both the business-to-business
              and business-to-consumer markets. <br />
              <br />
              <br />
              <TransitionLink
                to="/projects/ezimac/"
                color="#e3e3e3"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default KoandcoProject
